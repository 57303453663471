import React from 'react';
import './PageConstruct.css';

const PageConstruct: React.FC = () => {
  return (
    <div className="aviso-construcao">
      <h1>🚧 Página em Construção 🚧</h1>
      <p>Estamos trabalhando para trazer esta página para você em breve. Obrigado pela sua paciência!</p>
    </div>
  );
};

export default PageConstruct;
