import React, { useState } from 'react';
import './Menu.css';

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="menu">
      {/* Ícone do Menu de 3 linhas para mobile */}
      <div className="menu-toggle" onClick={toggleMenu}>
        <div className="hamburger-icon"></div>
      </div>

      {/* Itens do Menu Principal */}
      <ul className={`menu-items ${isMenuOpen ? 'active' : ''}`}>
        <li><a href="#">Home</a></li>
        <li><a href="#">Sobre</a></li>
        <li><a href="#">Serviços</a></li>
        <li><a href="#">Contato</a></li>
      </ul>

      
    </div>

    
  );
};

export default Menu;
