import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Conteúdo opcional dentro do footer */}
        <p>BroscoTech.com.br - Todos os direitos reservados © 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
