// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CompactLoginMenu.css */

.compact-login-container {
  position: relative;
  display: inline-block;
}

.login-icon-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease;
}

.login-icon-button:hover {
  color: #007bff;
}

.login-menu {
  position: absolute;
  top: 40px;
  right: 0;
  width: 200px;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

.login-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-button {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
}
`, "",{"version":3,"sources":["webpack://./src/App/CompactLoginMenu.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,wCAAwC;EACxC,WAAW;AACb;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,WAAW;EACX,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* CompactLoginMenu.css */\n\n.compact-login-container {\n  position: relative;\n  display: inline-block;\n}\n\n.login-icon-button {\n  background: none;\n  border: none;\n  font-size: 24px;\n  cursor: pointer;\n  color: #333;\n  transition: color 0.3s ease;\n}\n\n.login-icon-button:hover {\n  color: #007bff;\n}\n\n.login-menu {\n  position: absolute;\n  top: 40px;\n  right: 0;\n  width: 200px;\n  padding: 15px;\n  background-color: #fff;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n  z-index: 10;\n}\n\n.login-input {\n  width: 100%;\n  padding: 8px;\n  margin-bottom: 10px;\n  border: 1px solid #ddd;\n  border-radius: 4px;\n}\n\n.login-button {\n  width: 100%;\n  padding: 8px;\n  font-size: 16px;\n  border: none;\n  border-radius: 4px;\n  background-color: #007bff;\n  color: #fff;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.login-button:hover {\n  background-color: #0056b3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
