import React from 'react';
import './WhatsappButton.css'; // Arquivo de estilos

const WhatsappButton: React.FC = () => {
  return (
    <div className="whatsapp-container">
      <a 
        href="https://wa.me/5541991747318" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="whatsapp-button"
      >
        <i className="fab fa-whatsapp"></i> {/* Ícone do WhatsApp */}
      </a>
    </div>
  );
}

export default WhatsappButton;
