import React from 'react';
import './BackgroundImage.css';
import PageConstruct from '../components/PageConstruct';
import Loading from '../components/Loading';

const BackgroundImage: React.FC = () => {
  return (
    <div>
      <div>
          <div className="background-image">
            <div className="content"></div>
              <PageConstruct /><Loading />

          </div>
      </div>
    </div>
  );
};

export default BackgroundImage;
