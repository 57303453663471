// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: inherit;
      flex-direction: unset;
  }
  
  .spinner {
    width: 60px;
    height: 60px;
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  `, "",{"version":3,"sources":["webpack://./src/components/Loading.css"],"names":[],"mappings":"AAAA;MACM,aAAa;MACb,mBAAmB;MACnB,uBAAuB;MACvB,kBAAkB;MAClB,qBAAqB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,6BAA6B;IAC7B,kBAAkB;IAClB,kCAAkC;EACpC;;EAEA;IACE,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;EACpC","sourcesContent":[".loading-container {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      flex-wrap: inherit;\n      flex-direction: unset;\n  }\n  \n  .spinner {\n    width: 60px;\n    height: 60px;\n    border: 8px solid #f3f3f3;\n    border-top: 8px solid #3498db;\n    border-radius: 50%;\n    animation: spin 1s linear infinite;\n  }\n  \n  @keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
