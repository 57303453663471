// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aviso-construcao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 60vh; /* Ajusta conforme necessário */
    color: #333;
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .aviso-construcao h1 {
    font-size: 2.5rem;
    color: #4f198b; /* Cor de alerta */
    margin-bottom: 10px;
  }
  
  .aviso-construcao p {
    font-size: 1.2rem;
    color: #666;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/PageConstruct.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,YAAY,EAAE,+BAA+B;IAC7C,WAAW;IACX,8BAA8B;IAC9B,aAAa;EACf;;EAEA;IACE,iBAAiB;IACjB,cAAc,EAAE,kBAAkB;IAClC,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,WAAW;EACb","sourcesContent":[".aviso-construcao {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    height: 60vh; /* Ajusta conforme necessário */\n    color: #333;\n    font-family: Arial, sans-serif;\n    padding: 20px;\n  }\n  \n  .aviso-construcao h1 {\n    font-size: 2.5rem;\n    color: #4f198b; /* Cor de alerta */\n    margin-bottom: 10px;\n  }\n  \n  .aviso-construcao p {\n    font-size: 1.2rem;\n    color: #666;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
