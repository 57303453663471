// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  padding: inherit;
  display: flex;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ocupa a altura completa da viewport */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 120px;
  }
}

.App-header {
  height: 100px;
  margin: 0;
  padding: inherit;
  position: fixed;
  z-index: 1000;
  align-items: center;
  background-color: #fcfcfc;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;
  width: 100% !important;
  flex-wrap: nowrap;
  align-content: space-around;
  resize: auto;
  display: flex;
  justify-content: space-evenly;
  align-content: space-around;
  flex-wrap: nowrap;
  flex-direction: row;
}

.App-link {
  color: #61dafb;
}

body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}


.Menu-out{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
  gap: inherit;
  resize: auto;
  padding: inherit;
}  
`, "",{"version":3,"sources":["webpack://./src/App/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,6BAA6B;EAC7B,aAAa;EACb,sBAAsB;EACtB,iBAAiB,EAAE,wCAAwC;AAC7D;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;IAC5C,aAAa;EACf;AACF;;AAEA;EACE,aAAa;EACb,SAAS;EACT,gBAAgB;EAChB,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,+CAA+C;EAC/C,MAAM;EACN,OAAO;EACP,sBAAsB;EACtB,iBAAiB;EACjB,2BAA2B;EAC3B,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,2BAA2B;EAC3B,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,YAAY;AACd;;;AAGA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,qBAAqB;EACrB,6BAA6B;EAC7B,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".App {\n  text-align: center;\n  padding: inherit;\n  display: flex;\n  justify-content: space-evenly;\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh; /* Ocupa a altura completa da viewport */\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n    height: 120px;\n  }\n}\n\n.App-header {\n  height: 100px;\n  margin: 0;\n  padding: inherit;\n  position: fixed;\n  z-index: 1000;\n  align-items: center;\n  background-color: #fcfcfc;\n  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n  top: 0;\n  left: 0;\n  width: 100% !important;\n  flex-wrap: nowrap;\n  align-content: space-around;\n  resize: auto;\n  display: flex;\n  justify-content: space-evenly;\n  align-content: space-around;\n  flex-wrap: nowrap;\n  flex-direction: row;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\nbody, html, #root {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n}\n\n\n.Menu-out{\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-content: center;\n  justify-content: space-around;\n  align-items: center;\n  gap: inherit;\n  resize: auto;\n  padding: inherit;\n}  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
