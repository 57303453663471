import React, { useState } from 'react';
import './CompactLoginMenu.css';

const CompactLoginMenu: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Usuário:', username);
    console.log('Senha:', password);
    setIsMenuOpen(false); // Fecha o menu após o login
  };

  return (
    <div className="compact-login-container">
      <button onClick={toggleMenu} className="login-icon-button">
        <i className="fa fa-user-circle"></i> {/* Ícone de login */}
      </button>

      {isMenuOpen && (
        <div className="login-menu">
          <form onSubmit={handleLogin}>
            <input
              type="text"
              placeholder="Usuário"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="login-input"
            />
            <input
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="login-input"
            />
            <button type="submit" className="login-button">
              Entrar
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default CompactLoginMenu;
