import React from 'react';
import '../components/Loading.css';


function Loading() {
  return (
    <div className="loading-container">
      <div className="spinner"></div>
    </div>
  );
}

export default Loading;
