// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/footer-image.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Caminho para a imagem */
    background-size: cover;
    background-position: center;
    height: 100%; /* Ajuste o tamanho conforme necessário */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    margin-top: auto; /* Empurra o footer para o final da página */
  }
  
  .footer-content {
    background-color: rgba(0, 0, 0, 0.349); /* Sobreposição para melhorar a legibilidade */
    padding: 10px;

  }
`, "",{"version":3,"sources":["webpack://./src/App/Footer.css"],"names":[],"mappings":"AAAA;IACI,yDAAkD,EAAE,0BAA0B;IAC9E,sBAAsB;IACtB,2BAA2B;IAC3B,YAAY,EAAE,yCAAyC;IACvD,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;IACX,kBAAkB;IAClB,gBAAgB,EAAE,4CAA4C;EAChE;;EAEA;IACE,sCAAsC,EAAE,8CAA8C;IACtF,aAAa;;EAEf","sourcesContent":[".footer {\n    background-image: url('./assets/footer-image.jpg'); /* Caminho para a imagem */\n    background-size: cover;\n    background-position: center;\n    height: 100%; /* Ajuste o tamanho conforme necessário */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #fff;\n    text-align: center;\n    margin-top: auto; /* Empurra o footer para o final da página */\n  }\n  \n  .footer-content {\n    background-color: rgba(0, 0, 0, 0.349); /* Sobreposição para melhorar a legibilidade */\n    padding: 10px;\n\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
