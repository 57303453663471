// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../App/assets/background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    color: #fff;
    font-family: Arial, sans-serif;
    text-align: center;
    overflow: hidden;
    flex-direction: column;
  }
  
  .background-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
    opacity: 0.1; /* Define a transparência da imagem */
    z-index: -1; /* Coloca a imagem atrás do conteúdo */
  }
  
  .content {
    position: relative;
    z-index: 1; /* Garante que o conteúdo esteja acima do fundo */
    padding: 20px;
  }
  
.content h1 {
  font-size: 4rem; /* Aumenta o tamanho da fonte */
  font-weight: bold;
  color: #fff;
  margin-bottom: 20px;
}

.content p {
  font-size: 1.5rem; /* Aumenta o tamanho da fonte do parágrafo */
  color: #fff;
  max-width: 800px;
  margin: 0 auto;
}
`, "",{"version":3,"sources":["webpack://./src/App/BackgroundImage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,WAAW;IACX,8BAA8B;IAC9B,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,yDAAqD;IACrD,sBAAsB;IACtB,2BAA2B;IAC3B,YAAY,EAAE,qCAAqC;IACnD,WAAW,EAAE,sCAAsC;EACrD;;EAEA;IACE,kBAAkB;IAClB,UAAU,EAAE,iDAAiD;IAC7D,aAAa;EACf;;AAEF;EACE,eAAe,EAAE,+BAA+B;EAChD,iBAAiB;EACjB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,iBAAiB,EAAE,4CAA4C;EAC/D,WAAW;EACX,gBAAgB;EAChB,cAAc;AAChB","sourcesContent":[".background-image {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: auto;\n    width: auto;\n    color: #fff;\n    font-family: Arial, sans-serif;\n    text-align: center;\n    overflow: hidden;\n    flex-direction: column;\n  }\n  \n  .background-image::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-image: url('../App/assets/background.jpg');\n    background-size: cover;\n    background-position: center;\n    opacity: 0.1; /* Define a transparência da imagem */\n    z-index: -1; /* Coloca a imagem atrás do conteúdo */\n  }\n  \n  .content {\n    position: relative;\n    z-index: 1; /* Garante que o conteúdo esteja acima do fundo */\n    padding: 20px;\n  }\n  \n.content h1 {\n  font-size: 4rem; /* Aumenta o tamanho da fonte */\n  font-weight: bold;\n  color: #fff;\n  margin-bottom: 20px;\n}\n\n.content p {\n  font-size: 1.5rem; /* Aumenta o tamanho da fonte do parágrafo */\n  color: #fff;\n  max-width: 800px;\n  margin: 0 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
